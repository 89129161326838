import React, { useState } from 'react';
import { useTranslation } from 'next-i18next';
import classes from './RequestDialog.module.scss';
import { saveCustomerRequest } from '../../../../../redux/auth/actions';
import Box from '../../../../../remitbee/components/box/Box';
import Dialog from '../../../../../remitbee/components/dialogs/Dialog';
import Typography from '../../../../../remitbee/components/typography/Typography';
import Input from '../../../../../remitbee/components/input/Input';
import Button from '../../../../../remitbee/components/button/Button';
import CloseIcon from '../../../../../remitbee/icons/CloseIcon'
import Divider from '../../../../../remitbee/components/divider/Divider';
import { snackbar } from '../../../../../remitbee/components/snackbar/SnackBar';

interface RequestDialogProps {
    open: boolean;
    isPublic?: boolean;
    handleClose: Function;
    request_type: string;
}

const RequestDialog : React.FC<RequestDialogProps> = ({ open, handleClose, request_type, isPublic}) => {
    const { t } = useTranslation(isPublic ? 'landing' : 'common');
    const [submitted, setSubmitted] = useState(false);
    const [values, setValues] = useState({
      request_type : request_type,
      data: '',
      email: ''
    });
  
    const handleChange = (value, name) => {
      setValues({ ...values, [name]: value });
    };
  
    const handleSubmit = async () => {
      const { success, data, errorMessage} = await saveCustomerRequest(values.email, values.data, values.request_type ? values.request_type.toUpperCase() : null);
      success && data?.saveCustomerRequest ? setSubmitted(true) : snackbar.error(errorMessage, 3000);
    };
  
    const close = () => {
      setValues({
        ...values,
        data: '',
        email: '',
      });
      setSubmitted(false);
      handleClose(false);
    };

    return (
      <Dialog 
        id='requestDialog' 
        open={open}
        variant={submitted ? 'success' : 'text'}
        hideClose={!submitted}
        >
            <Box>
                <Box display='flex' justifyContent='space-between'>
                    <Box pt={submitted ? 12 : 2}>
                        <Typography align={submitted ? 'center' : 'flex-start'} className={classes[`rb-dialog-header`]}>
                            {submitted ? 'Success!' : t(`request_dialog.${request_type}_title`)}
                        </Typography>
                    </Box>
                    {!submitted && <Box display='flex' justifyContent='center' style={{ width: '20px' }}>
                        <span onClick={close} className={`${classes[`rb-closeIcon`]}`}><CloseIcon /></span>
                    </Box>}
                </Box>
                <Box pt={4} pb={4}>
                    <Typography align={submitted ? 'center' : 'flex-start'} className={submitted ? classes[`rb-dialog-submitted-text`] : classes[`rb-dialog-text`]}>
                        {submitted ? `We received your request and will send you an email with bonus once your ${request_type} is available.` : t(`request_dialog.${request_type}_description`)}
                    </Typography>
                </Box>
                {!submitted && <>
                <Divider />
                <Box pt={6}>
                    <Input
                        label={t(`request_dialog.${request_type}_label`)}
                        value={values.data}
                        required
                        fullWidth
                        onChange={(e) => handleChange(e.target.value, 'data')}
                        placeholder={t(`request_dialog.${request_type}_placeholder`)}
                    />
                </Box>
                <Box pt={6}>
                    <Input
                        label={'Email address'}
                        value={values.email}
                        fullWidth
                        required
                        onChange={(e) => handleChange(e.target.value, 'email')}
                        placeholder={'Enter email address'}
                    />
                </Box>
                </>}
                <Box pt={6}>
                <Button
                    onClick={submitted ? close :handleSubmit}
                    id="submit_request_dialog"
                    fullWidth
                    disabled={
                        !submitted &&
                        values.data === '' ||
                        !values.email.includes('@') ||
                        !values.email.includes('.co')
                    }
                    >
                    {submitted ? 'Got It!' : 'Submit'}
                </Button>
                </Box>
            </Box>
      </Dialog>
    );
  };
    
export default RequestDialog;
  